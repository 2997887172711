import { useState, FC, useEffect } from 'react';
import { FormControl, IconButton, TableCell, TableRow, TextField, Select, MenuItem, Checkbox, Autocomplete } from '@mui/material';
import showIcon from '../../assets/images/show.svg';
import deleteIcon from '../../assets/images/delete.svg';
import crossIcon from '../../assets/images/cross.svg';
import acceptIcon from '../../assets/images/plus.svg';
import { IUser, IUserLookUp, IUserUpdate } from '../../API/interfaces/users.interface';
import { getRoleLabel, RoleEnum } from '../../API/enums/Role.enum';
import admin from '../../assets/images/admin.svg';
import curator from '../../assets/images/curator.svg';
import worker from '../../assets/images/worker.svg';

interface IUsersItemProps {
    user: IUser;
    curators: IUserLookUp[];
    onUpdate: (userId: string, payload: IUserUpdate) => void;
    onDelete: (userId: string) => void;
}

const UsersItem: FC<IUsersItemProps> = ({ user, curators, onUpdate, onDelete }) => {
    const [isEditMode, setEditMode] = useState(false);

    const [login, setLogin] = useState(user.login || '');
    const [firstName, setFirstName] = useState(user.firstName || '');
    const [middleName, setMiddleName] = useState(user.middleName || '');
    const [lastName, setLastName] = useState(user.lastName || '');
    const [role, setRole] = useState(user.role);
    const [isActive, setActive] = useState(user.isActive);
    const [selectedCurator, setSelectedCurator] = useState(curators.find((curator) => curator.id === user.curatorId) || null);

    const resetValues = () => {
        setEditMode(false);
        setLogin(user.login);
        setFirstName(user.firstName);
        setLastName(user.lastName);
        setMiddleName(user.middleName || '');
        setRole(user.role);
        setActive(user.isActive);
        setSelectedCurator(curators.find((curator) => curator.id === user.curatorId) || null);
    }

    useEffect(() => {
        if (role === RoleEnum.EMPLOYEE && !selectedCurator && curators[0]) {
            return setSelectedCurator(curators[0]);
        }

        if (role !== RoleEnum.EMPLOYEE && selectedCurator) {
            return setSelectedCurator(null);
        }
    }, [curators, selectedCurator, role])

    const handleUpdate = () => {
        setEditMode(false);
        onUpdate(
            user.id,
            {
                login,
                firstName,
                middleName,
                lastName,
                role,
                isActive,
                curatorId: selectedCurator?.id || null
            }
        );
    }

    const handleDelete = () => onDelete(user.id);

    const disableValues = !(login && firstName && lastName);

    const getRoleIcon = 
    user.role === 0? admin: 
        user.role === 1? curator: 
            user.role === 2? worker: '#'

    if (isEditMode) return (
        <TableRow sx={{ background: '#018DCA33' }}>
            <TableCell align="center">
                <Checkbox checked={isActive} onChange={() => setActive(!isActive)} />
            </TableCell>
            <TableCell align="center">
                <TextField
                    type="text"
                    variant="outlined"
                    sx={{ background: '#fff', borderRadius: '7px' }}
                    size="small"
                    value={login}
                    onChange={(e) => setLogin(e.target.value)}
                />
            </TableCell>
            <TableCell align="center">
                <TextField
                    type="text"
                    variant="outlined"
                    sx={{ background: '#fff', borderRadius: '7px' }}
                    size="small"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />
            </TableCell>
            <TableCell align="center">
                <TextField
                    type="text"
                    variant="outlined"
                    sx={{ background: '#fff', borderRadius: '7px' }}
                    size="small"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
            </TableCell>
            <TableCell align="center">
                <TextField
                    type="text"
                    variant="outlined"
                    sx={{ background: '#fff', borderRadius: '7px' }}
                    size="small"
                    value={middleName}
                    onChange={(e) => setMiddleName(e.target.value)}
                />
            </TableCell>
            <TableCell align="center">
                <FormControl variant='outlined' size="small" sx={{ width: '200px', background: '#fff', borderRadius: '7px' }}>
                    <Select value={role} onChange={(e) => setRole(e.target.value as RoleEnum)}>
                        {
                            Object.values(RoleEnum).filter((type) => !isNaN(+type)).map((role) => (
                                <MenuItem value={role} key={role}>{getRoleLabel(role as RoleEnum)}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </TableCell>
            <TableCell align="center">
                <Autocomplete fullWidth
                    size='small'
                    disablePortal
                    sx={{ background: '#fff', borderRadius: '7px' }}
                    options={curators}
                    getOptionLabel={(option) => option.fullName}
                    value={selectedCurator}
                    onChange={(e, value) => { setSelectedCurator(value); }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </TableCell>
            <TableCell align="center">
                <IconButton onClick={resetValues}>
                    <img src={crossIcon} alt="X" />
                </IconButton>
                <IconButton
                    onClick={handleUpdate}
                    disabled={disableValues}
                    sx={{ opacity: disableValues ? '.6' : '1' }}
                >
                    <img src={acceptIcon} alt="✓" />
                </IconButton>
            </TableCell>
        </TableRow>
    )


    return (
        <TableRow>
            <TableCell align="center">
                <Checkbox checked={isActive} disabled={true} />
            </TableCell>
            <TableCell align="center">{user.login}</TableCell>
            <TableCell align="center">{user.lastName}</TableCell>
            <TableCell align="center">{user.firstName}</TableCell>
            <TableCell align="center">{user.middleName}</TableCell>

            <TableCell align="center" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '97px' }}>
                <img src={getRoleIcon} alt="" style={{marginRight: '10px'}} />
                {getRoleLabel(user.role)}
            </TableCell>
            <TableCell align="center">{user.curator?.lastName} {user.curator?.firstName}</TableCell>
            <TableCell align="center" sx={{ minWidth: '120px' }}>
                <IconButton onClick={() => setEditMode(true)}>
                    <img src={showIcon} alt="показать" />
                </IconButton>
                <IconButton onClick={handleDelete}>
                    <img src={deleteIcon} alt="удалить" />
                </IconButton>
            </TableCell>
        </TableRow>
    )
}

export default UsersItem;
