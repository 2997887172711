import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";
import { IUserPerformanceDto } from "../../API/interfaces/analytics.interface";
import { getTimeString } from "../../utils/time";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ProgressBar from "@ramonak/react-progress-bar";
import cross from '../../assets/images/cross_popup.svg';

export interface IUserPerformanceItemProps {
    isAdmin: boolean;
    isEmployee: boolean;
    userPerformance: IUserPerformanceDto;
    toggle: React.DispatchWithoutAction;
}

const UserPerformanceItem: React.FC<IUserPerformanceItemProps> = ({ userPerformance, isAdmin, isEmployee, toggle }) => {
    const [isOpen, toggleIsOpen] = React.useReducer(v => !v, false);
    const finishedAt = new Date(userPerformance.createdAt);
    const startedAt = new Date(finishedAt.getTime() - userPerformance.time * 1000);
    const withSectionStats = userPerformance.sectionsStats && userPerformance.sectionsStats.length > 0;

    const correct = userPerformance.correctNormalQuestions + userPerformance.correctHelpQuestions;
    const incorrect = userPerformance.incorrectNormalQuestions + userPerformance.incorrectHelpQuestions;
    const maxValue = correct + incorrect

    const questionType =
        userPerformance.sectionsStats?.map(section => section.answers.map(answer => {
            return answer.question.type
        }))[0][0]

    const handleToggle = () => {
        toggle();
        toggleIsOpen()
    }

    return (
        <React.Fragment>
            <TableRow>
                <TableCell align="center">
                    <span style={{
                        width: '16px', height: '16px', borderRadius: '10px',
                        display: 'block', margin: '1px auto 0 auto',

                        background:
                            questionType === 0 ? '#018DCA' :
                                questionType === 1 ? '#7a7785' :
                                    questionType === 2 ? '#F46141CC' :
                                        questionType === 3 ? 'transparent' : '#fff',

                        border:
                            `1px solid ${questionType === 0 ? '#transparent' :
                                questionType === 1 ? '#transparent' :
                                    questionType === 2 ? '#transparent' :
                                        questionType === 3 ? '#797979' : '#fff'
                            }`
                    }}

                    ></span>
                </TableCell>
                {!isEmployee && <TableCell align="center">{userPerformance.user?.lastName} {userPerformance.user?.firstName}</TableCell>}
                {isAdmin && <TableCell align="center">{userPerformance.curator?.lastName ? userPerformance.curator?.lastName + ' ' + userPerformance.curator?.firstName : '-'}</TableCell>}
                <TableCell align="center">
                    {userPerformance.totalSections + ' | ' + userPerformance.totalAttempts}
                </TableCell>
                <TableCell align="center">{startedAt.toLocaleString().slice(0, 10)}</TableCell>
                <TableCell align="center">
                    <Box sx={{ display: 'flex', justifyContent: 'center', height: '31px' }}>
                        <ProgressBar
                            completed={String(correct)} maxCompleted={maxValue}
                            width='110px' height='31' bgColor='#018DCA' borderRadius='6px' baseBgColor='#F46141'
                            labelAlignment='left' />
                        <span style={{ position: 'relative', right: '16px', top: '5px', fontSize: '16px', color: '#fff' }}
                        >
                            {incorrect}
                        </span>
                    </Box>
                </TableCell>
                <TableCell align="right">
                    {
                        (
                            <IconButton onClick={handleToggle} disabled={!withSectionStats}>
                                {isOpen ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                        )
                    }
                </TableCell>
            </TableRow>
            {
                withSectionStats && (
                    <TableRow>
                        <TableCell style={{ padding: 0 }} colSpan={10}>
                            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                                <Box>
                                    <Table>
                                        <TableHead className="tableHead">
                                            <TableRow>
                                                <TableCell align='center'>Время старта</TableCell>
                                                <TableCell align='center'>Время прохождения</TableCell>
                                                <TableCell align='center'>Количество освоенных модулей</TableCell>
                                                <TableCell align='center'>Ответы на наводящие вопросы</TableCell>
                                                <TableCell align='right' width='50px'>
                                                    <IconButton onClick={handleToggle}>
                                                        <img src={cross} alt="X" />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align='center'>{startedAt.toLocaleString().slice(11)}</TableCell>
                                                <TableCell align='center'>{getTimeString(userPerformance.time)}</TableCell>
                                                <TableCell align='center'>

                                                    <Box sx={{ display: 'flex', justifyContent: 'center', height: '31px' }}>
                                                        <ProgressBar
                                                            completed={String(userPerformance.passedSections)}
                                                            maxCompleted={userPerformance.totalSections}
                                                            width='110px' height='31' bgColor='#018DCA' borderRadius='6px'
                                                            labelAlignment='left' />
                                                        <span style={{ position: 'relative', right: '16px', top: '5px', fontSize: '16px' }}
                                                        >
                                                            {userPerformance.totalSections}
                                                        </span>
                                                    </Box>

                                                </TableCell>
                                                <TableCell align='center'>
                                                    <Box sx={{ display: 'flex', justifyContent: 'center', height: '31px' }}>
                                                        <ProgressBar
                                                            completed={String(userPerformance.correctHelpQuestions)}
                                                            maxCompleted={userPerformance.correctHelpQuestions + userPerformance.incorrectHelpQuestions}
                                                            width='110px' height='31' bgColor='#018DCA' borderRadius='6px' baseBgColor='#F46141'
                                                            labelAlignment='left' />
                                                        <span style={{ position: 'relative', right: '16px', top: '5px', fontSize: '16px', color: '#fff' }}
                                                        >
                                                            {userPerformance.incorrectHelpQuestions}
                                                        </span>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    <Table>
                                        <TableHead sx={{ background: '#018DCA33', color: '#018DCA', height: '70px', border: '1px solid #018DCA' }}>
                                            <TableRow sx={{ borderRadius: '6px 6px 0 0' }}>
                                                <TableCell align="center" width="300px" sx={{ borderBottom: '1px solid #018DCA', color: '#018DCA' }}>
                                                    Модуль
                                                </TableCell>
                                                <TableCell align="center" width="300px" sx={{ borderBottom: '1px solid #018DCA', color: '#018DCA' }}>
                                                    Тип
                                                </TableCell>
                                                <TableCell align="center" width="300px" sx={{ borderBottom: '1px solid #018DCA', color: '#018DCA' }}>
                                                    Вопрос
                                                </TableCell>
                                                <TableCell align="center" width="300px" sx={{ borderBottom: '1px solid #018DCA', color: '#018DCA' }}>
                                                    Ответ
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow
                                                sx={{ height: '55px', borderBottom: '1px solid #c8c8c8', background: '#f8f9fa' }}
                                            >
                                                <TableCell>
                                                    
                                                </TableCell>
                                                <TableCell>
                                                    
                                                </TableCell>
                                                <TableCell>
                                                    
                                                </TableCell>
                                                <TableCell>
                                                    
                                                </TableCell>
                                            </TableRow>
                                            {
                                                userPerformance.sectionsStats?.map((section) => section.answers.map((answer) => (
                                                    <TableRow key={answer.question.name}>
                                                        <TableCell sx={{ maxWidth: "300px" }} align="center"><p className="ellipsis">{section.name}</p></TableCell>
                                                        <TableCell sx={{ maxWidth: "300px", background: '#018DCA33' }} align="center">
                                                            <span

                                                                style={{
                                                                    width: '16px', height: '16px', borderRadius: '10px',
                                                                    display: 'block', margin: '1px auto 0 auto',

                                                                    background:
                                                                        answer.question.type === 0 ? '#018DCA' :
                                                                            answer.question.type === 1 ? '#7a7785' :
                                                                                answer.question.type === 2 ? '#F46141CC' :
                                                                                    answer.question.type === 3 ? 'transparent' : '#fff',

                                                                    border:
                                                                        `1px solid ${answer.question.type === 0 ? '#transparent' :
                                                                            answer.question.type === 1 ? '#transparent' :
                                                                                answer.question.type === 2 ? '#transparent' :
                                                                                    answer.question.type === 3 ? '#797979' : '#fff'
                                                                        }`
                                                                }}></span>
                                                        </TableCell>
                                                        <TableCell sx={{ maxWidth: "300px" }} align="center"><p className="ellipsis">{answer.question.name}</p></TableCell>
                                                        <TableCell sx={{ maxWidth: "300px", color: answer.choice.isCorrect ? "#018DCA" : "#F46141" }} align="center"><p className="ellipsis">{answer.choice.text}</p></TableCell>
                                                    </TableRow>
                                                )))
                                            }
                                        </TableBody>
                                    </Table>
                                </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                )
            }
        </React.Fragment>
    )
}

export default UserPerformanceItem;