import {Box, Button, Stack, TextField, Typography} from '@mui/material';
import {useState} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../Context/Auth';

const AuthPage = () => {
    const { signIn } = useAuth();
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const location = useLocation();
    const navigate = useNavigate();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            setError('');
            await signIn({ login, password });
            navigate(location.state?.from || '/');
        } catch (err) {
            setError('Неверный логин или пароль');
        }
    }

    return (
        <Box sx={{position: 'fixed', backgroundColor: '#c8c8c8', top: 0, left: 0, right: 0, bottom: 0, zIndex: 10000}}>
            <Box maxWidth="xs" sx={{width: '420px',height: '420px', marginLeft: 'auto', marginRight: 'auto', marginTop: 'calc(50vh - 210px)', padding: 0, borderRadius: '6px', background: '#fff'}}>
                <form style={{display: 'grid', rowGap: '30px'}} onSubmit={handleSubmit} >
                    <Stack direction="row" alignItems="center" justifyContent="center">
                        <Typography color='white' textAlign='center' fontWeight='700' fontSize='40px' className='tableHead' height='96px' width='100%' borderRadius='6px 6px 0 0' padding='25px 0'>LMS Quests</Typography>
                    </Stack>
                    <Box sx={{display: 'grid', rowGap: '4px', width: '140px', maxWidth: '350px', justifySelf: 'center'}}>

                        <TextField label='Логин' required={true} variant='standard' type='text' value={login} sx={{width: '140px'}}
                                   onChange={e => setLogin(e.target.value)}/>
                    </Box>
                    <Box sx={{display: 'grid', rowGap: '4px', width: '140px', maxWidth: '350px', justifySelf: 'center'}}>

                        <TextField label='Пароль' required={true} variant='standard' type='password' sx={{width: '140px'}}
                                   value={password} onChange={e => setPassword(e.target.value)}/>
                    </Box>
                    <Button size="large" variant='contained' type="submit" fullWidth
                            sx={{maxWidth: '158px', justifySelf: 'center', marginTop: '42px'}} disabled={!(login && password)}
                            >Войти</Button>

                 
                        { error && <Typography color='error' textAlign="center">{error}</Typography> }
                
                    {/* <p style={{ fontSize: '15px', margin: '7px 0 10px 4px' }}>
                Забыли пароль?
                <span style={{ color: '#1876d1', cursor: 'pointer' }}> Вспомнить</span>
            </p> */}
                </form>
            </Box>
        </Box>
    )
}
export default AuthPage;