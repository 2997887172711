import apiClient, { ApiClient } from '../apiClient';
import { IAuth, SignupDto } from '../interfaces/auth.interface';

export class AuthService {
    constructor(private api: ApiClient){}

    public async login(credentials: SignupDto){
        return this.api.post<IAuth>('auth/login', credentials)
            .then(auth => {
                this.api.setToken(auth.accessToken);
                return auth;
            });
    }
}

const authService = new AuthService(apiClient);
export default authService;